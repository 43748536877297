import { motion, useMotionValue, useTransform } from "framer-motion";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export function HoldingPage() {
    const x = useMotionValue(200);
    const y = useMotionValue(200);

    const rotateX = useTransform(y, [0, 400], [10, -10]);
    const rotateY = useTransform(x, [0, 400], [-10, 10]);

    function handleMouse(event) {
        const rect = event.currentTarget.getBoundingClientRect();

        x.set(event.clientX - rect.left);
        y.set(event.clientY - rect.top);
    }

    return (
        <div>
            <motion.div
                style={{
                    width: 400,
                    height: 400,
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                    perspective: 400,
                    margin: "auto",
                    marginBottom: 40
                }}
                onMouseMove={handleMouse}
            >
                <motion.div
                    style={{
                        width: 700,
                        height: 500,
                        borderRadius: 30,
                        rotateX,
                        rotateY
                    }}
                >
                <img src={"ping-gorilla-logo.png"} className="logo"/>
                </motion.div>
            </motion.div>
            <Card sx={{ maxWidth: 600 }}>
                <CardActionArea>
                    <CardContent>
                        <img src={"ping-gorilla-logo-text.svg"} alt="Ping Gorilla"/>
                        <Typography variant="body1">
                            cloud broadcast production tools
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            {/* <BottomNavigation
            showLabels
            >
                <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
                <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
                <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
            </BottomNavigation> */}
        </div>
    );
}
