import { useState } from "react";
import { createRoot } from "react-dom/client";
import { HoldingPage } from "./HoldingPage";
import { Refresh } from "./refresh";
import "./styles.css";

const App = () => {
    const [count, setCount] = useState(0);
    return (
        <>
            <HoldingPage key={count} />
            <Refresh onClick={() => setCount(count + 1)} />
        </>
    );
};

const root = createRoot(document.getElementById("root"));
root.render(<App />);
